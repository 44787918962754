<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Rack</v-card-title>
            <div v-if="init_loading">
                <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-card-text>
                    <v-data-table :headers="headers" :items="roleLink_list" class="elevation-1 nowrap" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line
                                    hide-details> </v-text-field>
                                <span style="width: 20px;"></span>
                                <v-btn class="mb-2 button" @click="addRack">Add Rack</v-btn>
                                <v-dialog v-model="dialog" max-width="500px">
                                    <v-card>
                                        <v-form v-model="valid" ref="form">
                                            <v-card-title class="heading">
                                                <span class="headline">{{ formTitle }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12" sm="12" md="12" class="fields"><br>
                                                            <label>Display Order</label><span style="color: red;"> *</span>
                                                            <v-text-field dense outlined v-model="editedItem.displayOrder"
                                                                item-text="roleLink_list_id" item-value="id" persistent-hint
                                                                type="number" :error="errorMsg.displayOrder ? true : false"
                                                                :error-messages="errorMsg.displayOrder ? 'Required' : ''"
                                                                :rules="[v => !!v || 'required']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" class="fields">
                                                            <label>Rack Number</label><span style="color: red;"> *</span>
                                                            <v-text-field dense outlined v-model="editedItem.rackNo"
                                                                item-text="roleLink_list_id" item-value="id" persistent-hint
                                                                :disabled="!inSave" :error="errorMsg.rackNo ? true : false"
                                                                :error-messages="errorMsg.rackNo ? 'Required' : ''"
                                                                :rules="[v => !!v || 'required']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" class="fields">
                                                            <label>Block Number</label><span style="color: red;"> *</span>
                                                            <v-text-field dense outlined :disabled="!inSave"
                                                                v-model="editedItem.blockNo" item-text="roleLink_list_id"
                                                                item-value="id" persistent-hint
                                                                :error="errorMsg.blockNo ? true : false"
                                                                :error-messages="errorMsg.blockNo ? 'Required' : ''"
                                                                :rules="[v => !!v || 'required']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" class="fields">
                                                            <label>Location Identifier</label><span style="color: red;"> *</span>
                                                            <v-text-field dense outlined v-model="editedItem.location"
                                                                item-text="roleLink_list_id" item-value="id" persistent-hint
                                                                :error="errorMsg.location ? true : false"
                                                                :error-messages="errorMsg.location ? 'Required' : ''"
                                                                :rules="[v => !!v || 'required']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" class="fields">
                                                            <label>Capacity</label><span style="color: red;"> *</span>
                                                            <v-text-field dense outlined v-model="editedItem.capacity"
                                                                item-text="roleLink_list_id" item-value="id" persistent-hint
                                                                type="number" :error="errorMsg.capacity ? true : false"
                                                                :error-messages="errorMsg.capacity ? 'Required' : ''"
                                                                :rules="[v => !!v || 'required']"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" class="fields">
                                                            <label>Book Category</label><span style="color: red;"> *</span>
                                                            <v-autocomplete dense outlined :items="bookCategoryList"
                                                                item-value="bookCategoryName" item-text="bookCategoryName"
                                                                v-model="editedItem.bookCategory"
                                                                persistent-hint></v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                                                <v-btn class="button" @click="save" :disabled="!valid">Save</v-btn>
                                            </v-card-actions>
                                        </v-form>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div>
                                <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteRack(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        valid: false,
        init_loading: false,
        errorMsg: {
            roleLinkId: false,
        },
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Rack',
                disabled: true,
                href: '',
            },
        ],
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        org: "",
        inSave: true,
        dialog: false,
        headers: [
            {
                text: "Sr no",
                align: "left",
                sortable: true,
                value: "srno",
            },
            {
                text: "Display Order",
                align: "left",
                sortable: true,
                value: "displayOrder",
            },
            {
                text: "Rack Number",
                align: "left",
                sortable: true,
                value: "rackNo",
            },
            {
                text: "Bloack Number",
                align: "left",
                sortable: true,
                value: "blockNo",
            },

            {
                text: "Location Identifier",
                align: "left",
                sortable: true,
                value: "location",
            },
            {
                text: "Capacity",
                align: "left",
                sortable: true,
                value: "capacity",
            },
            {
                text: "Book Category",
                align: "left",
                sortable: true,
                value: "bookCategory",
            },

            { text: "Actions", value: "action", sortable: false },
            { text: "Active", value: "edit", sortable: false },
            { text: "Delete", value: "delete" },
        ],
        bookCategoryList: [],
        roleLink_list: [],
        role_list: [],
        editedIndex: -1,
        editedItem: {
            displayOrder: "",
            rackNo: "",
            blockNo: "",
            location: "",
            capacity: "",
            bookCategory: "",
            bookCategoryId: "",
            isactive: false,
            id: ""
        },
        defaultItem: {
            displayOrder: "",
            rackNo: "",
            blockNo: "",
            location: "",
            capacity: "",
            bookCategory: "",
            bookCategoryId: "",
            isactive: false,
            id: ""
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Rack" : "Edit Rack";
        },
    },

    // watch: {
    //     dialog(val) {
    //         val || this.close();
    //     },
    //     overlay(val) {
    //         val = () => {
    //             this.overlay = false;
    //         };
    //     },
    // },
    mounted() {
        this.onLoad();
    },
    methods: {
        addRack() {
            this.inSave = true
            this.dialog = true;
            this.$refs.form.reset()
        },
        editItem(item) {
            this.inSave = false
            this.editedIndex = this.roleLink_list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            var name = item.role;
            for (let x in this.role_list) {
                // alert(this.role_list[x].name+" : "+name)
                if (this.role_list[x].name == name) {
                    this.editedItem.role = this.role_list[x].name;
                    this.editedItem.roleId = this.role_list[x].id;
                    // this.item.role = this.role_list[x].name
                    break;
                }
            }
        },
        deleteRack(item) {
            const data = {
                rackId: item.rackId,
            };
            axios
                .post("/admin/deleteRack", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Rack Deleted Successfully  !!");
                        this.onLoad();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        onLoad() {
            this.init_loading = true;
            axios
                .post("/Admin/getRackData")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.init_loading = false;
                        this.bookCategoryList = res.data.bookCategory_list
                        this.roleLink_list = res.data.rack_list;
                        this.role_list = res.data.role_list;
                        this.org = res.data.org;
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                    this.init_loading = false;
                    // var overlay = false;
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
            // this.$refs.form.reset()
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        save() {
            this.editedItem.displayOrder = parseInt(this.editedItem.displayOrder)
            this.editedItem.capacity = parseInt(this.editedItem.capacity)
            if (this.editedIndex > -1) {
                if (this.$refs.form.validate()) {
                    axios
                        .post("/Admin/editRack", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                //window.alert(res.data.msg)
                                Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                                this.showSnackbar("#4caf50", "Rack Updated Successfully..."); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            } else {
                // this.roleLink_list.push(this.editedItem)
                axios
                    .post("/Admin/saveRack", this.editedItem)
                    .then((res) => {
                        if (res.data.status.code == "SUCCESS") {
                            this.showSnackbar("#4caf50", "Rack Added Successfully!!!"); // show snackbar on success
                            this.onLoad();
                        } else if (res.data.status.code == "NA") {
                            this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
                this.close();
            }
        }, //save()

        activeOn(item) {
            axios
                .post("/admin/rackIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Rack updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        isValidated() {
            if (this.editedItem.roleLinkId) {
                return true;
            } else {
                if (!this.editedItem.roleLinkId) {
                    this.errorMsg.roleLinkId = true;
                }

                return false;
            }
        }, // .....end of isValidated()
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}
</style>
